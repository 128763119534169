import Vue from 'vue'
import App from './App.vue'
import store from './store/index'
import VueGtag from "vue-gtag";
//router
import VueRouter from 'vue-router'
//Meta
import Meta from 'vue-meta';

import * as VueGoogleMaps from 'vue2-google-maps'

import axios from 'axios'
import VueAxios from 'vue-axios'
import VueLodash from 'vue-lodash'
import lodash from 'lodash'
//icons 
import { library } from '@fortawesome/fontawesome-svg-core'
import { faInstagram, faLinkedin, faTwitterSquare, faFacebookSquare, faYoutubeSquare } from '@fortawesome/free-brands-svg-icons';
import { faPhoneAlt, faEnvelope, faTimes, faChevronDown, faChevronUp, faShareAltSquare } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import "./filters"

import VueLazyload from 'vue-lazyload'
import AOS from "aos";
import "aos/dist/aos.css";
import { vfmPlugin } from 'vue-final-modal'

Vue.use(VueLodash, { name: 'custom' , lodash: lodash })


library.add(faInstagram, faLinkedin, faTwitterSquare, faFacebookSquare, faPhoneAlt, faEnvelope, faTimes, faYoutubeSquare, faChevronDown, faChevronUp, faShareAltSquare)

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false
Vue.config.devtools = true

Vue.use(VueRouter);
Vue.use(Meta);
Vue.use(VueAxios, axios);

Vue.use(VueLazyload);
Vue.use(vfmPlugin);



function lazyLoad(view){
  return() => import(`@/views/${view}.vue`)
}

Vue.use(VueLazyload, {
  preLoad: 1.3,
  loading: 'images/loading.gif',
  attempt: 1
})

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyDqrgMBd-huANb5x9xV4gWOTWqaSXQQENU',
    libraries: 'places', // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)
 
    //// If you want to set the version, you can do so:
    // v: '3.26',
  },
 
  //// If you intend to programmatically custom event listener code
  //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
  //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
  //// you might need to turn this on.
  // autobindAllEvents: false,
 
  //// If you want to manually install components, e.g.
  //// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
  //// Vue.component('GmapMarker', GmapMarker)
  //// then disable the following:
  // installComponents: true,
})

const router = new VueRouter({
  mode: 'history',
  routes: [
    { path: '/', component: lazyLoad('Home') },
    { path: '/about', component: lazyLoad('About')},
    { path: '/team', component: lazyLoad('Team')},
    { path: '/press', component: lazyLoad('Press')},
    { path: '/testimonials', component: lazyLoad('Testimonials')},
    { path: '/listings', component: lazyLoad('Listings')},
    { path: '/sold', component: lazyLoad('Sold')},
    { path: '/contact', component: lazyLoad('Contact')},
    { path: '/property/:slug', component: lazyLoad('Property')},
    { path: '/exclusive-guide', component: lazyLoad('Subscription')},
    { path: '/search', component: lazyLoad('Search')},
    { path: "*", component: lazyLoad('PageNotFound') },
  ],
  scrollBehavior: function(to) {
    if (to.hash) {
        return { selector: to.hash }
    } else {
        return { x: 0, y: 0 }
    }
  },
})

Vue.use(VueGtag, {
  config: { id: "AW-747040475" },
  params: {
    send_page_view: true,
  },
  includes: [
    {id : 'UA-55470711-1'}
  ],
}, router);

new Vue({
  router,
  store,
  render: (h) => h(App),
  mounted() {
    AOS.init()
  },
}).$mount('#app')
