import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import VueAxios from 'vue-axios'
import 'es6-promise/auto'

Vue.use(Vuex)
Vue.use(VueAxios, axios)


const TWENTY_MINUTES =  20 * 60 * 1000

const state = {
    loaded: false,
    expiryDate: new Date,
    properties: [],
    reviews: []
}

// getters
const getters = {
    dateExpired: (state) => {
        return ((new Date) - state.expiryDate) > TWENTY_MINUTES;
    },
    getLoaded: (state) => {
        return state.loaded;
    },
    properties: (state) => {
        return state.properties;
    },
    reviews: (state) => {
        return state.reviews;
    }
}

// actions
const actions = {
    getProperties (context){
        context.commit('getProperties')
    },
    getReviews (context){
        context.commit('getReviews')
    },
}

// mutations
const mutations = {
    getProperties (state){
        state.loaded = false;
        axios.get('https://strapi.uptowncreative.io/meredith-schlosser-properties?_sort=Price:DESC&_limit=500')
        .then((response) => {
            state.properties = response.data
        })
        state.loaded = true;
        state.expiryDate = new Date;
    },
    getReviews (state){
        axios.get('https://strapi.uptowncreative.io/meredith-s-reviews?_sort=DisplayOrder')
        .then((response) => {
            state.reviews = response.data;
        })
    },
}

export default new Vuex.Store({
    state,
    getters,
    actions,
    mutations
})