<template>
  <div id="container">
    <!-- ==============================================
    Preloader
    =============================================== -->
    <transition 
        name="custom-classes-transition"
        leave-active-class="animated fadeOut">
        <div id="preloader" v-if="showLoader">
            <div id="loading-animation"></div>
        </div>
    </transition>
    <Header />
    <router-view></router-view>
    <Modal />
    <Footer> </Footer>
    <b id="tborder"></b>
    <b id="rborder"></b>
    <b id="bborder"></b>
    <b id="lborder"></b>
  </div>
</template>

<script>
import lazyLoadComponent from './utils/lazy-load-component';
import SkeletonBox from './components/SkeletonBox';
import Header from './components/Header'
import Modal from './components/MailSignupModal.vue'

const defaultOptions = {
  loading: SkeletonBox,
  loadingData: {
    props: {
      width: `100%`,
      height: `20em`,
    },
  },
};

export default {
  name: 'app',
  components: {
    Header,
    Modal,
    Footer: lazyLoadComponent({
      ...defaultOptions,
      componentFactory: () => import(`./components/Footer.vue`),
    }),
  },
  data(){
    return {
      loaded: false,
    }
  },
  computed:{
    showLoader(){
      if (this.$route.path === '/' && !this.loaded){
        return true;
      } else {
        return false;
      }
    }
  },
  watch:{
    $route (){
      this.loaded = false;
      window.setTimeout(() => {
        this.loaded = true;
      }, 800)
    }
  },
  mounted:function(){
    if(!this.$store.getters.getLoaded || this.$store.getters.dateExpired){
      this.$store.dispatch('getProperties');
      this.$store.dispatch("getReviews");
    }
    window.setTimeout(() => {
      this.loaded = true;
    }, 800)
  }
}

import 'vueperslides/dist/vueperslides.css';
</script>

<style lang="scss">
@import url('styles/reset.min.css');
@import url('https://fonts.googleapis.com/css?family=PT+Serif:400,700,400italic,700italic|Oswald&display=swap');
@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@0,400..800;1,400..800&display=swap');
@import 'styles/style';

</style>

<style lang="scss">
.cool-lightbox .cool-lightbox-thumbs .cool-lightbox-thumbs__list .cool-lightbox__thumb:before{
    border-color: #9B4D7E !important;
}
</style>