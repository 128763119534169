<template>
    <vue-final-modal v-model="showModal" classes="modal-container" content-class="modal-content">
        <div class="mail-container">
            <button class="modal__close" @click="showModal = false">
                <img src="/images/close.svg" alt="Close icon">
            </button>
            <div id="mc_embed_signup">
                <h2>Want to stay up-to-date on the latest industry news
                And Local inventory? Join our Newsletter Today</h2>
                <form
                    action="https://meredithschlosser.us7.list-manage.com/subscribe/post?u=a88ddc5ec59588a175d73b9c1&amp;id=3eecbe0c77"
                    method="post" id="mc-embedded-subscribe-form"
                    name="mc-embedded-subscribe-form"
                    class="validate"
                    target="_blank"
                    novalidate>
                    <div id="mc_embed_signup_scroll">
                        <input aria-label="Enter your email address" type="email" value="" name="EMAIL" class="email" id="mce-EMAIL" placeholder="email@address.com" required>
                        <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
                        <div style="position: absolute; left: -5000px;" aria-hidden="true">
                            <input type="text" name="b_a88ddc5ec59588a175d73b9c1_3eecbe0c77" tabindex="-1" value="">
                        </div>
                        <input aria-label="Submit to subscribe our newsletter" type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" class="button" v-bind:disabled="!checked" @click="showModal = false">
                    </div>
                </form>
                <div class="user-consent">
                    <input aria-label="Check to authorize receiving promotional emails" type="checkbox" id="consent" name="consent" v-model="checked">
                    <label for="consent">I would like to receive promotional emails.</label>
                </div>
            </div>
        </div>
    </vue-final-modal>
</template>

<script>
export default {
  data: () => ({
    showModal: false,
    checked: false,
  }),
  mounted:function(){
      setTimeout(()=> {
          this.showModal = true
      }, 60000);
  },
}
</script>

<style lang="scss" scoped>
::v-deep .modal-container {
    display: flex;
    justify-content: center;
    align-items: center;
}
::v-deep .modal-content {
    width: 50%;
    min-width: 360px;
}
.modal__close {
  position: absolute;
  top: 16px;
  right: 8px;
}

.modal__close img{
    width: 30px;
}

button{
    background: transparent;
    border: none;
    width: unset;
    &:hover{
        cursor: pointer;
    }
}

.mail-container{
    position: relative;
    display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	color: #fff;
	padding: 60px 0;
	background-color: #000;
}

.mail-container h2{
	font-weight: 300;
    font-size: 22px;
	color: #fff;
	text-transform: uppercase;
	text-align: center;
    max-width: 500px;
    margin: 0px auto;
    margin-bottom: 60px;
}

.mail-container label{
	margin-left: 5px;
	color: #fff;
}

.mail-container #mc_embed_signup {
	min-width: 320px;
	width: 80%;
	max-width: 500px;
}

.mail-container #mc_embed_signup_scroll{
	position: relative;
}

.mail-container #mc_embed_signup_scroll input{
	height: 54px;
	background: #fff;
}
.mail-container #mc_embed_signup_scroll #mce-EMAIL{
	min-width: 200px;
	width: 100%;
	max-width: 100%;
}

.mail-container #mc_embed_signup_scroll #mc-embedded-subscribe{
	margin: 0;
	position: absolute;
	top: 4px;
	right: 5px;
	height: 42px;
    padding: 0 16px;
    outline: none;
    border: 2px solid #a9976d;
    background: #a9976d;
    color: #fff;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 18px;
    line-height: 30px;
	cursor: pointer;
	font-family: "Oswald",Helvetica,Arial,sans-serif;
    line-height: 40px;
}


.mail-container #mc_embed_signup_scroll #mc-embedded-subscribe[disabled]{
	opacity: 0.3;
    cursor: not-allowed;
}

.mail-container .user-consent input{
	appearance: checkbox;
    -webkit-appearance: checkbox;
	background: #fff;
	width: 16px;
	height: 16px;
	vertical-align: text-bottom;
}

@media (max-width: $mobile) { 
    #mce-EMAIL{
        margin-left: 10px;
        max-width: 300px !important;
        display: block;
    }
    #mc-embedded-subscribe{
        right: 20px !important;
    }
    .user-consent{
        margin-left: 10px;
    }
    .mail-container h2{
        max-width: 300px;
        font-size: 19px;
    }
}
</style>

